import React, { useEffect, useLayoutEffect } from "react";
import "./App.scss";
import HeroContainer from "./components/HeroContainer";
import { useStore } from "./store/storeUtils";
import OverlayHandler from "./components/overlay/OverlayHandler";
import inizializeAnalytics from "./utils/analytics.js";
import { datadogRum } from "@datadog/browser-rum";

if (process.env.REACT_APP_NAMESPACE === "biz-tecma-prod") {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    // site refers to the Datadog site parameter of your organization
    // see Getting Started with Datadog Sites
    site: "datadoghq.eu",
    service: "emotions",
    env: process.env.REACT_APP_NAMESPACE,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 50,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
}
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE,
};

inizializeAnalytics(tagManagerArgs);

const App = () => {
  const store = useStore();
  const urlParams = new URLSearchParams(window.location.search);

  let goFullscreen = urlParams.get("goFullscreen");
  let isNavbarLink = urlParams.get("isNavbarLink");
  let disableNoTap = urlParams.get("disableNoTap");
  let latuacasaUrl = urlParams.get("latuacasaUrl");

  if (goFullscreen !== null) {
    store.setGoFullscreen(goFullscreen);
  }
  if (isNavbarLink !== null) {
    store.setIsNavbarLink(isNavbarLink);
  }
  if (disableNoTap !== null) {
    store.setDisableNoTap(disableNoTap);
  }
  if (latuacasaUrl !== null) {
    store.setLatuacasaUrl(latuacasaUrl);
  }

  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById("white-loader")) {
        document.getElementById("white-loader").style.display = "none";
      }
    }, 10 * 1000);
  }, []);

  useLayoutEffect(() => {
    setInterval(() => {
      store.setComponentMode(false);
      if (document.getElementById("floorplanning-app")) {
        store.setComponentMode(true);
      }
    }, 10 * 1000);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="app-container">
      <OverlayHandler />
      <div className="app-hero-container">
        <HeroContainer />
      </div>
    </div>
  );
};

export default App;
